import { FunctionComponent, createContext, useState, useContext, useEffect } from "react";
import classnames from "classnames";
import { WithTranslations, HasContainers } from "../../../services/localisation/types";
import { addTranslations } from "../../../services/localisation/utils";
import { AlertFilled, CloseIcon, TrainIcon, TruckIcon, InfoCircleFilledBlackIcon, CancelledWhiteIcon } from "../../Icons";
import { PlainButton } from "../../Button/button";
import { hasCustomers } from "../../Controls/CustomerControl/customer-control";
import { withAuthentication } from "../../../services/authentication/authentication.provider";
import styles from "./status-message.module.scss";
import { InlineLink } from "../Link";

const defaultTranslations = {
  statusMessageRail: "",
  statusMessageRoad: "",
  statusMessageRelease: "",
  statusWarningRail: "",
  statusWarningRoad: "",
  statusWarningRelease: "",
  statusInfoRail: "",
  statusInfoRoad: "",
  statusInfoRelease: "",
  releaseReadMore: "Read more"
};

const NO_OP = () => {};
const defaultState: StatusMessageContext = {
  open: true,
  showStatusMessage: NO_OP,
  hideStatusMessage: NO_OP
};

interface StatusMessageContext {
  open: boolean;
  showStatusMessage: () => void;
  hideStatusMessage: () => void;
}

export const StatusMessageStateContext = createContext(defaultState);
export const StatusMessageStateProvider: FunctionComponent = ({ children }) => {
  const storedState = localStorage.getItem("statusMessageOpen");
  const initialState = storedState ? JSON.parse(storedState) : defaultState.open;
  const [open, setOpen] = useState(initialState);

  useEffect(() => {
    const storedItem = localStorage.getItem("statusMessageOpen");
    if (storedItem) {
      const item = JSON.parse(storedItem);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem("statusMessageOpen");
      } else {
        setOpen(item.value === "true");
      }
    }
  }, []);

  const showStatusMessage = () => {
    const item = {
      value: "true",
      expiry: null
    };
    localStorage.setItem("statusMessageOpen", JSON.stringify(item));
    setOpen(true);
  };

  const hideStatusMessage = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7); // expire in 1 week
    const item = {
      value: "false",
      expiry: expirationDate.getTime()
    };
    localStorage.setItem("statusMessageOpen", JSON.stringify(item));
    setOpen(false);
  };


  return (
    <StatusMessageStateContext.Provider
      value={{
        open,
        showStatusMessage,
        hideStatusMessage
      }}
    >
      {children}
    </StatusMessageStateContext.Provider>
  );
};

const UntranslatedStatusMessage: FunctionComponent<
  WithTranslations<{}, typeof defaultTranslations> & HasContainers
> = withAuthentication(({ translations = defaultTranslations, ...props }) => {
  const { open, hideStatusMessage, showStatusMessage } = useContext(StatusMessageStateContext);

  const showRailMessages = hasCustomers("rail", props.auth.userInformation);
  const showRoadMessages = hasCustomers("road", props.auth.userInformation);

  const translationIds = {
    statusMessageRail: "main.statusMessageRail",
    statusMessageRoad: "main.statusMessageRoad",
    statusMessageRelease: "main.statusMessageRelease",
    statusWarningRail: "main.statusWarningRail",
    statusWarningRoad: "main.statusWarningRoad",
    statusWarningRelease: "main.statusWarningRelease",
    statusInfoRail: "main.statusInfoRail",
    statusInfoRoad: "main.statusInfoRoad",
    statusInfoRelease: "main.statusInfoRelease"
  };

  const isValidTranslation = (translation: string, defaultId: string) => {
    return translation !== "undefined" && translation !== defaultId; // Because value can default to its id even if undefined
  };

  const railMessage = isValidTranslation(translations.statusMessageRail, translationIds.statusMessageRail)
    ? translations.statusMessageRail
    : undefined;
  const roadMessage = isValidTranslation(translations.statusMessageRoad, translationIds.statusMessageRoad)
    ? translations.statusMessageRoad
    : undefined;
  const releaseMessage = isValidTranslation(translations.statusMessageRelease, translationIds.statusMessageRelease)
    ? translations.statusMessageRelease
    : undefined;

  const railWarning = isValidTranslation(translations.statusWarningRail, translationIds.statusWarningRail)
    ? translations.statusWarningRail
    : undefined;
  const roadWarning = isValidTranslation(translations.statusWarningRoad, translationIds.statusWarningRoad)
    ? translations.statusWarningRoad
    : undefined;
  const releaseWarning = isValidTranslation(translations.statusWarningRelease, translationIds.statusWarningRelease)
    ? translations.statusWarningRelease
    : undefined;

  const railInfo = isValidTranslation(translations.statusInfoRail, translationIds.statusInfoRail)
    ? translations.statusInfoRail
    : undefined;
  const roadInfo = isValidTranslation(translations.statusInfoRoad, translationIds.statusInfoRoad)
    ? translations.statusInfoRoad
    : undefined;
  const releaseInfo = isValidTranslation(translations.statusInfoRelease, translationIds.statusInfoRelease)
    ? translations.statusInfoRelease
    : undefined;


  // every page reload compares and stores messages, shows msg if there's new
  useEffect(() => {

  const rawMessages = [
    translations.statusMessageRail,
    translations.statusMessageRoad,
    translations.statusMessageRelease,
    translations.statusWarningRail,
    translations.statusWarningRoad,
    translations.statusWarningRelease,
    translations.statusInfoRail,
    translations.statusInfoRoad,
    translations.statusInfoRelease
  ];
    const hasMessage = rawMessages.some(message => message !== "undefined");
    const storedMessages = JSON.parse(localStorage.getItem("statusMessages") || "[]");
    const isNewMessage = rawMessages.some(message => !storedMessages.includes(message));
    
    if (hasMessage && isNewMessage) {
      showStatusMessage();
      localStorage.setItem("statusMessages", JSON.stringify(rawMessages));
    }
  }, [
    translations.statusMessageRail,
    translations.statusMessageRoad,
    translations.statusMessageRelease,
    translations.statusWarningRail,
    translations.statusWarningRoad,
    translations.statusWarningRelease,
    translations.statusInfoRail,
    translations.statusInfoRoad,
    translations.statusInfoRelease
  ]);

  const addTypesToMessages = (
    releaseMessage?: string,
    railMessage?: string,
    roadMessage?: string,
    releaseInfo?: string,
    railInfo?: string,
    roadInfo?: string,
    releaseWarning?: string,
    railWarning?: string,
    roadWarning?: string
  ): { message: string; type: string }[] => {
    const messages: { message: string; type: string }[] = [];

    if (releaseMessage) {
      messages.push({ message: releaseMessage, type: "message" });
    }

    if (railMessage && showRailMessages) {
      messages.push({ message: railMessage, type: "message" });
    }

    if (roadMessage && showRoadMessages) {
      messages.push({ message: roadMessage, type: "message" });
    }

    if (railWarning && showRailMessages) {
      messages.push({ message: railWarning, type: "warning" });
    }

    if (roadWarning && showRoadMessages) {
      messages.push({ message: roadWarning, type: "warning" });
    }

    if (releaseWarning) {
      messages.push({ message: releaseWarning, type: "warning" });
    }

    if (railInfo && showRailMessages) {
      messages.push({ message: railInfo, type: "infoRail" });
    }

    if (roadInfo && showRoadMessages) {
      messages.push({ message: roadInfo, type: "infoRoad" });
    }

    if (releaseInfo) {
      messages.push({ message: releaseInfo, type: "info" });
    }

    return messages.filter((elem, index, all) => all.indexOf(elem) === index);
  };

  const statusMessages = addTypesToMessages(
    releaseMessage,
    railMessage,
    roadMessage,
    releaseInfo,
    railInfo,
    roadInfo,
    releaseWarning,
    railWarning,
    roadWarning
  );

  return (
    <>
      {statusMessages.length > 0 && open && props.auth.userInformation && (
        <div className={styles.statusMessageContainer}>
          {statusMessages.map(({ message, type }, index) => (
            <div
              key={index}
              className={classnames(styles.messageContainer, {
                [styles.warning]: type === "warning",
                [styles.message]: type === "message"
              })}
            >
              <div className={classnames(styles.icon)}>
                {type === "message" ? <AlertFilled /> : null}
                {type === "warning" ? <CancelledWhiteIcon /> : null}
                {type === "infoRail" ? <TrainIcon /> : null}
                {type === "infoRoad" ? <TruckIcon /> : null}
                {type === "info" ? <InfoCircleFilledBlackIcon /> : null}
              </div>
              <div className={styles.statusMessage}>
                <p className={styles.singleStatusMessage}>
                  {message.trim().endsWith(" ") ? message : message + " "}
                  {type === "info" ? (
                    <InlineLink variant={"dark"} to={"/options/release-notes"} onClick={hideStatusMessage}>
                      {`${translations.releaseReadMore}`}
                    </InlineLink>
                  ) : null}
                </p>
              </div>
              <div className={classnames(styles.close)}>
                <PlainButton className={styles.close} onClick={() => hideStatusMessage()}>
                  <CloseIcon />
                </PlainButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
});

export const StatusMessage = addTranslations(UntranslatedStatusMessage, {
  statusMessageRail: { id: "main.statusMessageRail" },
  statusMessageRoad: { id: "main.statusMessageRoad" },
  statusMessageRelease: { id: "main.statusMessageRelease" },
  statusWarningRail: { id: "main.statusWarningRail" },
  statusWarningRoad: { id: "main.statusWarningRoad" },
  statusWarningRelease: { id: "main.statusWarningRelease" },
  statusInfoRail: { id: "main.statusInfoRail" },
  statusInfoRoad: { id: "main.statusInfoRoad" },
  statusInfoRelease: { id: "main.statusInfoRelease" },
  releaseReadMore: { id: "main.releaseReadMore" }
});
